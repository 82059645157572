<template>
    <Modal :title="title" v-model="isShowModal" width="550px" heigh="50px">
        <template #modal-content>
            <div class="modal-content-files">
                <div class="files-title">
                    <div>檔案名稱</div>
                    <div class="appendix">附件</div>
                </div>
                <div
                    v-for="(file, idx) in docList"
                    :key="idx"
                    class="files-link"
                >
                    <div>{{ file.name }}</div>
                    <a :href="file.link" class="download-link-icon" />
                </div>
            </div>
        </template>
        <template #modal-bottom>
            <Button
                buttonWord="關閉"
                buttonStyle="grey"
                @click="turnOffModal"
            />
        </template>
    </Modal>
</template>
<script>
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'

export default {
    name: 'InsuranceDocDownloadModal',
    emits: ['update:modelValue'],
    components: { Modal, Button },
    props: {
        modelValue: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        docList: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    methods: {
        turnOffModal: function () {
            this.$emit('update:modelValue', false)
        }
    },
    computed: {
        isShowModal: {
            get() {
                return this.modelValue
            },
            set(val) {
                this.$emit('update:modelValue', val)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
:deep(.files-title),
:deep(.files-link) {
    font-size: 14px;
    color: $sixth-black;
    display: grid;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 4fr 40px;
    min-height: 40px;
    place-items: center start;
}
:deep(.files-title) {
    font-weight: bold;
    .appendix {
        place-self: center center;
    }
}
:deep(.files-link) {
    box-sizing: border-box;
    padding: 5px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    word-break: break-all;
    .download-link-icon {
        place-self: center center;
        @media screen and (max-width: 576px) {
            margin-right: 10px;
        }
    }
    &:last-child {
        border-bottom: none;
    }
}
</style>
